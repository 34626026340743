import { createSlice } from '@reduxjs/toolkit';

type initialStateProps = {
  globalLoading: boolean,
}

const initialState:initialStateProps =  {
  globalLoading: false,
}

export const commonSlice = createSlice({
  name: 'common',
  initialState:initialState,
  reducers: {
    setGlobalLoading: (state:any, action: any) => {
      state.globalLoading = action?.payload
    },
    
  }
})

export const { setGlobalLoading } = commonSlice.actions


export default commonSlice.reducer
