import React from 'react';
import {RouterProvider} from 'react-router-dom'
// import routes from '@/routes';
import routes from '@/routes/lazyRoutes';
// import RouterElements from '@/routes/Router';
import useLoginDetector from '@/hooks/useLoginDetector';

import './App.css';

function App() {
  console.log('===app')
  // useLoginDetector()
  return (
    <RouterProvider  router={routes}></RouterProvider>
  );
}

export default App;
