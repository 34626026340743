import Header from '@/components/Layout/HomeHeader';
import Layout from '@/components/Layout';
import useLoginDetector from '@/hooks/useLoginDetector';

export default function Index (props:any) {
  useLoginDetector()
  return (
    <Layout>
      <Header />
      {
        props.children
      }
    </Layout>
  )
}