import Header from '@/components/Layout/Header';
import Layout from '@/components/Layout/HomeLayout';
import {Outlet, useNavigate} from 'react-router-dom';
import useLoginDetector from '@/hooks/useLoginDetector';

export default function Index (props:any) {
  useLoginDetector()
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}