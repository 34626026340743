import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '@/store/index';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const isPro = process.env.NODE_ENV === 'production'
const RenderDev = (<Provider store={store}>
  <App />
</Provider>)

const RenderPro = (
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
console.log('===env',isPro)
root.render(
  isPro?RenderPro:RenderDev
  // RenderPro
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
