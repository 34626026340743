import { createSlice } from '@reduxjs/toolkit';

const formatBrands = (data) => {
  if(data?.length) {
    data.map(((d)=>{
      d.children = d.secondary||[];
      if(d?.secondary?.length) {
        d.secondary.map((s)=>{
          s.children = s.brand||[];
          if(s?.brand?.length) {

          }else {
            // s.disabled =  true;
          }
        });
      }else {
        // d.disabled =  true;
      }
      
    }))

    console.log('/wirte.js [22]--1',data);
  }

}

const formatcategoryIds = (data) => {
  let ids = {}
  data.map(((d)=>{
    // let _id = []
    d?.secondary?.map((s)=>{
      s?.brand?.map(b=>{
        // ids.push([d.id,s.id,b.id])
        // ids.push([].concat([d.id],[s.id],[b.id]))
        ids[b.id] = [d.id,s.id,b.id]
      });
    });
  }))
  
  return ids

}

const formatBrandIdObj = (data) => {
  let ids = {}
  data.map(((d)=>{
    ids[d.id] = {
      ...d
    }
    d?.children?.map((s)=>{
      ids[d.id][s.id] = {
        ...s
      };
      s?.children?.map(b=>{
        ids[d.id][s.id][b.id] = {
          ...b,
        }
      });
    });
  }))
  
  return ids

}


export const writeSlice = createSlice({
  name: 'common',
  initialState:{
    tag:0,// 1头条 2xhs
    paramsConfig: {
      materials: [], //参考素材
      args:[] //品牌参数
    },
    material:{
      category:[],
      categoryIds:{},
    },
    blockId:[],
    discardMoadl: {
      discard: false,
      cancel: true
    },
    aiTool: {
      isShow: false,
      selected: false,
      isAI: false,
      x:100,
      y:100,
      selectionStr:'',
      askStr:'',
      type:1,//1 replace,2 below
    },
    toolTip: {
      x:0,
      y:0,
      isShow: false,
      selectionStr:''
    },
    contentClient: {
      offsetWidth:400,
      offsetTop:0,
      offsetLeft:0,
      offsetHeight:0
    },
    content: {
      fontNums:0,
      times:0,//统计文章生成时间
    },
    formParams: {
      isResult:false,
      outline:[],
      outlines:[],
      step:1,
      length: 600,
      writingArgsId: null,
      materialIds:[],
      intention:null,
      // intention:'挂式美的空调推荐',
      title:'',
      needOutline: 0,
    },
    selection:{},
    response: {
      loading: false,
      blockLoading: false,
      // updatedTime: (new Date()).toLocaleString(),
      updatedTime: null,
      outline:{},
      outlines:{
        outlines: [
          // {
          //   "content": "挂式美的空调的特点",
          //   "keyPoint": [
          //     "为什么选择挂式美的空调：满足个人需求、提升生活品质、可靠的品牌、舒适的使用体验、节约能源"
          //   ]
          // },
         
        ]
      },
      article:{
        'title':'',
        "articleId": "",
        "blocks": [],
      },
    },
  },
  reducers: {
    setGlobalLoading: (state, action) => {
      state.globalLoading = action?.payload
    },
    setWriteTag:(state,action) => {
      state.tag = action?.payload
    },
    setDiscardModal: (state, action) => {
      const discardMoadl = state.discardMoadl
      state.discardMoadl = {
        ...discardMoadl,
        ...action?.payload
      };
    },
    setAItool:(state,action) => {
      const aiTool = state.aiTool
      state.aiTool = {
        ...aiTool,
        ...action?.payload
      };
    },
    
    setTooltip:(state,action) => {
      const toolTip = state.toolTip
      state.toolTip = {
        ...toolTip,
        ...action?.payload
      };
    },
    setSelection:(state,action) => {
      const selection = state.selection
      state.selection = {
        ...selection,
        ...action?.payload
      };
    },
    setFormStore:(state,action) => {
      const formParams = state.formParams
      state.formParams = {
        ...formParams,
        ...action?.payload
      };
    },
    setResponse:(state,action) => {
      const response = state.response
      let payload = action?.payload || {};
      let obj = {
        ...response
      }
      for(let k in payload) {
        obj[k] = payload[k]
      }
      // console.log('/wirte.js [234]--1',payload);
      // console.log('/wirte.js [236]--1','obj',obj);
      state.response = obj;
    },
    setMaterial:(state,action) => {
      const material = state.material
      // const category = material.category
      formatBrands(action?.payload)
      
      const categoryIds = formatcategoryIds(action?.payload)
      // const brandIdObjs = formatBrandIdObj(action?.payload)
      state.material = {
        ...material,
        category:[...action?.payload],
        categoryIds: categoryIds,
      };
    },
    setParamsConfig:(state,action) => {
      const paramsConfig = state.paramsConfig
      // console.log('/wirte.js [221]--1',action?.payload,data);
      state.paramsConfig = {
        ...paramsConfig,
        ...action?.payload
      };
    },
    // blockId
    setBlockId:(state,action) => {
      // console.log('/wirte.js [205]--1',action?.payload);
      state.blockId = action?.payload;
    },
    setContentClient:(state,action) => {
      // console.log('/wirte.js [205]--1',action?.payload);
      state.contentClient = action?.payload;
    },
    setContent:(state,action) => {
      state.content = {
        ...state.content,
        ...action?.payload
      };
    },
  }
})

export const { 
  setGlobalLoading, 
  setDiscardModal, 
  setAItool, 
  setTooltip,
  setSelection,
  setResponse,
  setMaterial,
  setParamsConfig,
  setBlockId,
  setContentClient,
  setFormStore,
  setContent,
  setWriteTag
} = writeSlice.actions


export default writeSlice.reducer
