import styles from './index.module.css';
import Logo from '@/assets/login/login_logo.svg';
export default function Layout(propss:any) {
  console.log('//layout')
  return(<div className={styles['login-layout']} >
    <div className={styles['logo-bg']}>
      <img src={Logo} className={styles['logo']}/>
      {/* <img src={LoginBg} className={styles['logo-bg-img']}/> */}
    </div>
    <div style={{flex:1,}}>
      {
        propss?.children
      }
    </div>
  </div>)
}