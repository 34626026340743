import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    info:{},
    baseInfo:{}
  },
  reducers: {
    setUserInfo: (state:any, action: PayloadAction<any>) => {
      state.info = action?.payload
    },
    setBaseUserInfo: (state:any, action: PayloadAction<any>) => {
      state.baseInfo = action?.payload
    }

  }
})

const { setUserInfo, setBaseUserInfo } = userInfoSlice.actions


export default userInfoSlice.reducer
