import { Avatar, Row, Col } from "antd";
import styles from './index.module.css';

export default function Index(props: any) {
  console.log('/index.tsx [4]--1','sss');
  const top = props.children && props.children[0];
  const container = props.children && props.children.slice(1);
  
  return (
    <div className={styles.layout}>
      <div className={styles.top}>{top}</div>
      <div className={styles.container}>{container}</div>
    </div>
  );
}
