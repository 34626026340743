import { configureStore, PayloadAction } from '@reduxjs/toolkit';
// import {  } from '@reduxjs/toolkit';
import userReducer from "./user";
import commonReducer from './common';
import writeReducer from './wirte'

const store = configureStore({
    reducer: {
        userInfo: userReducer,
        common: commonReducer,
        write: writeReducer
    }
})
    
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
// export type AppDispatch = (action: PayloadAction<any>) => void;

export default store;