import React, { useEffect } from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import LoginLayout from './components/Layout';

function Index () {
  // console.log('.///index')
  return (<LoginLayout>
      <Outlet />
    </LoginLayout>)
}

export default Index;