import { Avatar, Row, Col, Menu } from "antd";
import {Outlet, useNavigate, useLocation} from 'react-router-dom';

import Logo from "@/assets/icons/logo.png";
import styles from './index.module.css';
import { useState, useEffect } from "react";
const items:any = [
  {
    key: '/operation',
    label: '生 图',
    // icon: <MailOutlined />,
  },
  {
    key: '/write',
    label: '文 章',
    // icon: <MailOutlined />,
  },
  {
    key: '/space',
    label: '视 频',
    // icon: <MailOutlined />,
  },
]
export default function Header(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname} = location
  const [activedKey,setActivedKey] = useState('/')
  
  useEffect(()=>{
    if(/\/space/.test(pathname)) {
      setActivedKey('/space')
    }else if(/\/write/.test(pathname)){
      setActivedKey('/write')
    }else {
      setActivedKey('/operation')
    }
  },[pathname])
  const onClick = (item:any) => {
    navigate(item.key)
  }
  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <img src={Logo} alt="" height={30}/>
        {/* <Menu
          onClick={onClick}
          selectedKeys={[activedKey]}
          items={items}
          mode="horizontal"
          style={{border:'none',lineHeight:'60px'}}
        /> */}
      </div>
      <div className={styles.middle}>
        {
          items.map((m:any)=>{
            return <span 
              className={`${styles['m-item']} ${activedKey===m.key&&styles['actived']}`} 
              key={m.key}
              onClick={()=>onClick(m)}
            >{m.label}</span>
          })
        }
      </div>
      <div className={styles.right}>
        <Avatar
          style={{ backgroundColor: "#5aafff", verticalAlign: "middle" }}
          size="large"
          // gap={40}
        >
          User
        </Avatar>
      </div>
    </header>
  );
}
