import { useRoutes,Navigate, createBrowserRouter, createRoutesFromElements, Route, Routes } from "react-router-dom";

import LoginRoot from '@/views/login/layout';
import ViewRoot from '@/views'

const routes = [
  
  {
    path: '/login',
    element: <LoginRoot />,
    children: [
      {
        path: '/login/register',
        lazy: async () => ({Component:(await import("@/views/login/register")).default}),
      },
      {
        path: '/login',
        lazy: async () => ({Component:(await import("@/views/login")).default}),
      },
      {
        path: '/login/password',
        lazy: async () => ({Component:(await import("@/views/login/password")).default}),
      },
    ],
  },
  {
    path: '/',
    element: <Navigate to="/operation" /> // 重定向到 /login
  },
  
  {
    path: '/space',
    element: <Navigate to="/space/bench" /> ,
  },
  {
    path: '/',
    element: <ViewRoot />,
    children:[
      {
        path: '/operation',
        lazy: async () => ({Component:(await import("@/views/operation")).default}),
      },
      {
        path: '/space',
        lazy: async () => ({Component:(await import("@/views/space")).default}),
        // element: <Navigate to="/space/home" /> ,
        children: [
          {
            path: '/space/bench',
            lazy: async () => ({Component:(await import("@/views/space/bench")).default}),
          },
          {
            path: '/space/bench/:id',
            lazy: async () => ({Component:(await import("@/views/space/id.b")).default}),
          },
          {
            path: '/space/material',
            lazy: async () => ({Component:(await import("@/views/space/material")).default}),
          },
          {
            path: '/space/material/:id',
            lazy: async () => ({Component:(await import("@/views/space/id.m")).default}),
          },
          {
            path: '/space/audio',
            lazy: async () => ({Component:(await import("@/views/space/audio")).default}),
          },
          
        ]
      },
      {
        path: '/space/video',
        lazy: async () => ({Component:(await import("@/views/space/video")).default}),
      },
      {
        path: '/write',
        element: <Navigate to="/write/home" /> ,
      },
      {
        path: '/write',
        lazy: async () => ({Component:(await import("@/views/write")).default}),
        // element: <Navigate to="/write/home" /> ,
        children: [
          {
            path: '/write/home',
            lazy: async () => ({Component:(await import("@/views/write/home")).default}),
          },
          {
            path: '/write/history',
            lazy: async () => ({Component:(await import("@/views/write/history")).default}),
          },
          {
            path: '/write/material',
            lazy: async () => ({Component:(await import("@/views/write/material")).default}),
          },
          
        ]
      },
      {
        path: '/example',
        lazy: async () => ({Component:(await import("@/views/example")).default}),
        children: [
          {
            path: '/example/canvas',
            lazy: async () => ({Component:(await import("@/views/example/canvas")).default}),
          },
          {
            path: '/example/segment',
            lazy: async () => ({Component:(await import("@/views/example/segment/index")).default}),
          },
          {
            path: '/example/segment/path',
            lazy: async () => ({Component:(await import("@/views/example/segment/path")).default}),
          },
          {
            path: '/example/transform',
            lazy: async () => ({Component:(await import("@/views/example/segment/transform")).default}),
          },
          {
            path: '/example/masonry',
            lazy: async () => ({Component:(await import("@/views/example/masonry")).default}),
          },
          {
            path: '/example/pubu',
            lazy: async () => ({Component:(await import("@/views/example/pubu")).default}),
          },
          // 
        ]
      },
    ]
  },
  
  {
    path: '/charts',
    lazy: async () => ({Component:(await import("@/views/charts")).default}),
  },
  
  
  {
    path: '/write/tt',
    lazy: async () => ({Component:(await import("@/views/write/tt")).default}),
  },
  {
    path: '/write/xhs',
    lazy: async () => ({Component:(await import("@/views/write/xhs")).default}),
  },
  
]

export default createBrowserRouter(routes);